import React, { useRef, Suspense } from 'react';
import {
  Typography,
  Box,
  Container,
  Section,
  // DetailsLink,
  Figure,
  Image,
  // GatsbyImage,
  Layout,
  Grid,
} from '../components';
import aboutImg from '../images/about/about.svg';
// import manImg from "../images/member.jpg";
import dotBg from '../images/dot-bg.png';
import isoImage from '../images/ISO.svg';
import styled from 'styled-components';
import { stats, technologies } from '../config/data';
import { useOnScreen, useVisibleContactForm } from '../hooks';

const ContactForm = React.lazy(() => import('../components/ContactForm'));

const DotBg = styled.div`
  background: url(${dotBg});
  padding: 40px 40px 0px;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
`;

// const PlayBtn = styled.div`
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

const TechBox = styled.div`
  border: 1px solid #152539;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const AboutPage = () => {
  const ref = useRef();
  const onScreen = useOnScreen(ref, '-50px');
  const showContactForm = useVisibleContactForm(onScreen);

  return (
    <Layout title="About">
      <Container mt={{ xs: 8, md: 12 }}>
        <Section>
          <Grid gridTemplateColumns={{ _: '1fr', sm: '3fr 2fr' }}>
            <Box>
              <Typography variant="heading1" mb={1}>
                Reimagining effective business for all since 2010
              </Typography>
              <Typography variant="paragraph1">
                At Spring Rain, we are dedicated to bringing out the best
                possible solutions at all development stages. Keeping in mind
                your business's intention, we put your products and services at
                the forefront of our priority mindset. Being in this industry
                for over 10+ years has given us valuable insights that we imply
                and further advance your development process. The idea of always
                being up to date with the latest technology is embedded in our
                DNA.
              </Typography>
              <Typography mt={2} variant="paragraph1">
                We have hands-on experience with all major frameworks and
                technology stacks out there. Once a project is agreed upon, we
                will quickly assemble a team and immediately start working on it
                as per your requirement. To ensure the best in class security
                and working standards, we have officially made ourselves ISO
                Certified.Which will ensure clients get the utmost security of
                their confidential data and the highest quality industry agreed
                working standard.
              </Typography>
            </Box>
            <Box display={{ _: 'none', sm: 'block' }}>
              <Figure
                src={aboutImg}
                alt="Improve your Business now!"
                barColor="#008FD4"
                barPosition="left"
                position="right"
                width="80%"
              />
            </Box>
          </Grid>
        </Section>
        <Section>
          <DotBg>
            <Grid
              gridTemplateColumns={{
                _: '1fr',
                sm: '1fr 1fr',
                md: '1fr 1fr 1fr',
              }}
            >
              {stats.map(stat => (
                <Grid key={stat.label}>
                  <Typography variant="heading3">{stat.value}</Typography>
                  <Typography variant="paragraph1" mb={5}>
                    {stat.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </DotBg>
        </Section>
        {/* <Section>
          <Grid container alignItems="center" spacing={8}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" mb={2}>
                What we do
              </Typography>
              <Typography variant="heading1">
                Inspire brilliant minds to innovate & create
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                border
                p={3}
                position="relative"
                width={{ xs: "100%", sm: "80%", md: "100%" }}
                ml={{ sm: "auto" }}
                mx={{ xs: "auto", md: "" }}
              >
                <GatsbyImage src="about/video.jpg" alt="Team Video" />
                <PlayBtn>
                  <Button>
                    <PlayCircleFilled fontSize="large" />
                  </Button>
                </PlayBtn>
              </Box>
            </Grid>
          </Grid>
        </Section> */}
        {/* <Section>
          <Typography variant="heading3" mb={{ xs: 3, md: 6 }}>
            Leadership Team
          </Typography>
          <Grid container spacing={10}>
            {[...Array(3)].map((_, index) => (
              <Grid item xs={12} sm={4} key={index}>
                <Box textAlign="center" mb={{ xs: 4, md: 0 }}>
                  <Figure
                    src={manImg}
                    alt="Improve your Business now!"
                    barColor="#008FD4"
                    barPosition="left"
                    position="right"
                    height="auto"
                    imgWidth="90%"
                  />
                  <Typography mt={2} fontWeight="bold">
                    Bogdan Shestakov
                  </Typography>
                  <Typography mt={1}>Head of Solutions Team</Typography>
                </Box>
              </Grid>
            ))}
          </Grid>

          <DetailsLink mt={4} to="/" variant="arrow">
            Show More Experts
          </DetailsLink>
        </Section> */}
        <Section>
          <Grid
            gridTemplateColumns={{
              _: '1fr',
              sm: '1f 1f',
              md: '1fr 1fr 1fr 1fr 1fr',
            }}
          >
            {technologies.map((tech, index) => (
              <Box key={index}>
                <TechBox>
                  <Image width="100px" src={tech} alt="Technology tool" />
                </TechBox>
              </Box>
            ))}
          </Grid>
        </Section>
        <Section ref={ref} textAlign="center">
          <Image src={isoImage} alt="ISO certified" />
        </Section>
        {showContactForm && (
          <Section>
            <Suspense fallback={<div />}>
              <ContactForm />
            </Suspense>
          </Section>
        )}
      </Container>
    </Layout>
  );
};

export default AboutPage;
